import Api from './api.service';

export default {
  get: (term = '') => Api.get(`specialties?page=${term.page}&filter[name]=${term.name}`),
  getAll: () => Api.get('specialties'),
  getId: (id) => Api.get(`specialties/${id}`),
  save: (data) => Api.post(`specialties/`, data),
  update: (id, data) => Api.put(`specialties/${id}`, data),
  delete: (id) => Api.delete(`specialties/${id}`),
  export: (filters) => Api.post('specialties/export', { filters }),
};
