import Api from './api.service';

export default {
  get: (term = '') => Api.get(`subspecialties?page=${term.page}&include=specialty&filter[name]=${term.name}&filter[specialty_id]=${term.specialty_id}`),
  getAll: (term = '') => Api.get(`subspecialties?include=specialty&filter[specialty_id]=${term.specialty_id}`),
  getId: (id) => Api.get(`subspecialties/${id}`),
  save: (data) => Api.post(`subspecialties/`, data),
  update: (id, data) => Api.put(`subspecialties/${id}`, data),
  delete: (id) => Api.delete(`subspecialties/${id}`),
  export: (filters) => Api.post('subspecialties/export', { filters }),
};
